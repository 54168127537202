import {
  GetAppManifestFn,
  EditorReadyFn,
  FlowEditorSDK,
  ComponentRef,
} from '@wix/yoshi-flow-editor';
import { WIX_COMMENTS_WIDGET_COMP_ID } from './constants/app-def-ids';
import { createPublicDataStore } from '~/services/public-data-service/public-data-service';
import { createCategoryService } from './services/category-service/category-service';
import { getIsBlogComments } from './components/CommentsWidget/controller/get-is-blog-comments';
import type { ResponsiveLayout } from '@wix/editor-platform-sdk-types';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  _platformOptions,
  flowAPI,
) => {
  editorSDK.addEventListener('widgetAdded', async (event) => {
    const publicDataStore = await createPublicDataStore({
      compId: event.detail.componentRef.id,
      editorSDK,
    });

    if (getIsBlogComments(appDefinitionId)) {
      return;
    } else {
      await setResponsiveness(appDefinitionId, editorSDK, event.detail.componentRef);

      const categoryService = createCategoryService({ httpClient: flowAPI.httpClient });
      const categoryId = await categoryService.createCategory({ appId: appDefinitionId });

      if (categoryId) {
        await publicDataStore.setCategoryId({
          categoryId,
        });
      }

      await publicDataStore.setIsResourceCoupledToUrl();
    }
  });
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  _contextParams,
  flowAPI,
) => {
  const { t } = flowAPI.translations;

  if (getIsBlogComments(flowAPI.environment.appDefinitionId)) {
    return appManifestBuilder.build();
  }

  return appManifestBuilder
    .configureWidget(WIX_COMMENTS_WIDGET_COMP_ID, (widgetBuilder) => {
      widgetBuilder
        .gfpp()
        .set('mainAction1', {
          label: t('gfpp.manage-comments'),
          onClick: () => {
            editorSDK.editor.openDashboardPanel('token', {
              url: 'comments',
              closeOtherPanels: true,
            });
          },
        })
        .set('mainAction2', { behavior: 'DEFAULT1' });
    })
    .configureController('wix-comments', (controllerBuilderFn) => {
      controllerBuilderFn.exports().set({
        members: {
          setResourceId: {
            kind: 'function',
            description: 'Sets a custom resource ID for comments widget',
            params: [
              {
                name: 'resourceId',
                description: 'string',
              },
            ],
          },
          setIsLocked: {
            kind: 'function',
            description: 'Sets locked state for comments widget',
            params: [
              {
                name: 'isLocked',
                description: 'boolean',
              },
            ],
          },
        },
      });
    })
    .build();
};

const setResponsiveness = async (appToken: string, sdk: FlowEditorSDK, compRef: ComponentRef) => {
  try {
    const oldResponsiveLayout = await sdk.document.responsiveLayout.get(appToken, {
      componentRef: compRef,
    });

    const newComponentLayouts = oldResponsiveLayout.componentLayouts.map((componentLayout) => {
      return {
        ...componentLayout,
        maxWidth: { type: 'px', value: 980 },
        width: { type: 'percentage', value: 100 },
        height: { type: 'auto' },
      };
    });

    const newItemLayouts = oldResponsiveLayout.itemLayouts.map((itemLayout) => {
      if (itemLayout.type === 'GridItemLayout') {
        return {
          ...itemLayout,
          justifySelf: 'center',
          alignSelf: 'center',
          margins: {
            top: { type: 'percentage', value: 0 },
            left: { type: 'percentage', value: 0 },
            right: { type: 'percentage', value: 0 },
            bottom: { type: 'percentage', value: 0 },
          },
        };
      }
      return itemLayout;
    });

    const newResponsiveLayout = {
      ...oldResponsiveLayout,
      componentLayouts: newComponentLayouts,
      itemLayouts: newItemLayouts,
    } as ResponsiveLayout;

    await sdk.document.responsiveLayout.update(appToken, {
      componentRef: compRef,
      responsiveLayout: newResponsiveLayout,
    });
  } catch (e) {
    return Promise.resolve();
  }
};
