import {
  Category,
  ReactionTypeType as CommentReactions,
  RepliesReactionTypeType as ReplyReactions,
  Type as MarkedLabelType,
  IconTypeType as ReactionIcon,
  QueryCategoryRequest,
  Category as ApiCategory,
} from '@wix/ambassador-comments-v1-category/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import {
  createCategory as apiCreateCategory,
  queryCategory as apiQueryCategory,
  getCategory as apiGetCategory,
} from '@wix/ambassador-comments-v1-category/http';
import { updateCategory as apiUpdateCategory } from '~/api/category-service.api';
import { assertFields } from '../../ts-utils';
import { fromServerCategory } from './category-service.mappers';

export const createCategoryService = ({ httpClient }: { httpClient: IHttpClient }) => {
  const service = {
    createCategory: createCategory(httpClient),
    queryCategories: queryCategories(httpClient),
    updateCategory: updateCategory(httpClient),
    getCategory: getCategory(httpClient),
  };

  return service;
};

const createCategory =
  (httpClient: IHttpClient) =>
  async ({ appId }: { appId: string }) => {
    const defaults: Partial<Category> = {
      name: 'Comments',
      guestCommenting: true,
      guestReactions: true,
      reactionType: CommentReactions.REACTIONS,
      repliesReactionType: ReplyReactions.REACTIONS,
      mainReaction: { iconType: ReactionIcon.THUMBS_UP },
      ratingsSettings: { ratingsEnabled: false, ratingRequired: false },
      markedCommentLabelType: MarkedLabelType.FEATURED,
    };
    const { data } = await httpClient.request(
      apiCreateCategory({ category: { ...defaults, appId } }),
    );
    return data.category?.id ?? undefined;
  };

const queryCategories = (httpClient: IHttpClient) =>
  async function (request: QueryCategoryRequest = { query: {} }) {
    const { data } = await httpClient.request(apiQueryCategory(request));
    assertFields(data, ['categories'], 'apiQueryCategory reponse');

    return data.categories.map(fromServerCategory);
  };

const getCategory = (httpClient: IHttpClient) => async (categoryId: string) => {
  const { data } = await httpClient.request(apiGetCategory({ categoryId }));
  assertFields(data, ['category'], 'apiGetCategory reponse');

  return fromServerCategory(data.category);
};

const updateCategory = (httpClient: IHttpClient) =>
  async function ({
    category,
    fieldsUpdating,
  }: {
    category: ApiCategory;
    fieldsUpdating: Omit<ApiCategory, 'revision' | 'id'>;
  }) {
    const { data } = await httpClient.request(
      apiUpdateCategory({
        category,
        fieldsUpdating,
      }),
    );

    return data;
  };
